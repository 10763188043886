export const IconBack = ({ className }) => {
  return (
    <svg
      className={`${className || ''} svg-inline`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7.9 13.9">
      <path d="M7.9,0.9c0-0.8-1-1.3-1.6-0.7l-6,6c-0.4,0.4-0.4,1,0,1.4l6,6c0.6,0.6,1.6,0.2,1.6-0.7V0.9z" />
    </svg>
  );
};
