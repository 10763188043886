export const IconMarker = ({ className }) => {
  return (
    <svg
      className={`${className || ''} svg-inline`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 11">
      <path d="M3.35938 10.1685C3.53516 10.4419 3.94531 10.4419 4.12109 10.1685C6.97266 6.06689 7.5 5.63721 7.5 4.11377C7.5 2.04346 5.82031 0.36377 3.75 0.36377C1.66016 0.36377 0 2.04346 0 4.11377C0 5.63721 0.507812 6.06689 3.35938 10.1685Z" />
    </svg>
  );
};
