export const IconBookmark = ({ className }) => {
  return (
    <svg
      className={`${className || ''} svg-inline`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 24">
      <path d="M0,24l9-5.3l9,5.3V2.3C18,1,17,0,15.8,0H2.3C1,0,0,1,0,2.3V24z" />
    </svg>
  );
};
